<template>
    <div class="auto-1440">
        <Breadcrumb type="grey" ref="breadcrumb" />
        <div class="personal">
            <div class="left">
                <h1>
                    <div class="cn">个人中心</div>
                    <div class="en">Personal Center</div>
                </h1>
                <Sidebar />
            </div>
            <div
                class="right"
                :class="$route.name == 'browse' || 'collect' ? 'haspagi' : ''"
            >
                <Title>
                    <h2>
                        <svg-icon icon-class="per_title"></svg-icon>
                        <span>{{ title }}</span>
                    </h2>
                </Title>
                <transition name="fade-transform" mode="out-in">
                    <router-view :key="$route.fullPath"></router-view>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import Sidebar from '~/components/Personal/Sidebar'
import Breadcrumb from '~/components/common/Breadcrumb'
import Title from '~/components/Personal/Title'
export default {
    metaInfo() {
        return {
            title: '个人中心-文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: '个人中心-文物出版社',
                },
                {
                    name: 'description',
                    content: '个人中心-文物出版社',
                },
            ],
        }
    },
    components: {
        Breadcrumb,
        Sidebar,
        Title,
    },
    data() {
        return {
            title: '',
        }
    },
    watch: {
        $route(v) {
            this.title = v.meta.title
        },
    },
    created() {
        this.title = this.$route.meta.title
    },
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/personal.less');
.breadcrumb {
    // padding-bottom: 10px;
    border-bottom: 0;
}
.personal {
    display: flex;
    // padding-top: 20px;
    // padding-bottom: 80px;
    background-color: #f8f8f8;
    padding: 0.6rem;
    margin-bottom: 80px;
    .left {
        width: 260px;
        padding-right: 40px;
        margin-right: 20px;
        border-right: 1px solid #dee2e8;
    }
    .right {
        flex: 1;
        // background-color: #f5f5f5;
        padding: 5px 40px 50px;
    }
}
.haspagi {
    // background-color: transparent !important;
    padding: 5px 0 0 !important;
    :deep(.title) {
        padding-top: 0;
        margin: 0 40px;
        flex-shrink: 0;
    }
    display: flex;
    flex-direction: column;
}
h1 {
    margin-bottom: 30px;
    .cn {
        font-size: 28px;
        color: rgb(0, 15, 35);
        font-weight: bold;
    }
    .en {
        font-size: 16px;
        color: rgb(136, 136, 136);
        font-weight: normal;
    }
}
</style>