<template>
    <div class="sidebar">
        {{ forumShow }}
        <div class="green">
            <a-menu style="width: 100%" mode="inline" :defaultOpenKeys="openKeys" :default-selected-keys="selectkeys"
                @select="select">
                <template v-for="item in newRoutes">
                    <template v-if="item.name == 'audit'">
                        <template v-if="userInfo.IsComment">
                            <a-sub-menu :key="item.name" v-if="item.children">
                                <span slot="title"><span class="title">{{
                                    item.breadcrumbName || item.meta.title
                                }}</span></span>
                                <a-menu-item v-for="subitem in item.children" :key="subitem.name">
                                    <span class="subtitle">{{ subitem.breadcrumbName || item.meta.title }}<i></i></span>
                                </a-menu-item>
                            </a-sub-menu>
                            <a-menu-item v-else :key="item.name">
                                <span class="subtitle">{{ item.breadcrumbName || item.meta.title }}<i></i></span>
                            </a-menu-item>
                        </template>
                    </template>
                    <template v-else>
                        <a-sub-menu :key="item.name" v-if="item.children">
                            <span slot="title"><span class="title">{{
                                item.breadcrumbName || item.meta.title
                            }}</span></span>
                            <a-menu-item v-for="subitem in item.children" :key="subitem.name">
                                <span class="subtitle">{{ subitem.breadcrumbName || item.meta.title }}<i></i></span>
                            </a-menu-item>
                        </a-sub-menu>
                        <a-menu-item v-else :key="item.name">
                            <span class="subtitle" >{{ item.breadcrumbName || item.meta.title }}<i></i></span>
                        </a-menu-item>
                    </template>
                </template>
                <a-menu-item  key="logOff">
                    <span class="subtitle" @click.stop="logOff">注销账号<i></i></span>
                </a-menu-item>
            </a-menu>
        </div>
    </div>
</template>
<script>
import routes from "~/router";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
    data() {
        return {
            perRoutes: [],
            openKeys: [],
            selectkeys: [],
        };
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
            isLogin: (state) => state.user.isLogin,
            loginDialog: (state) => state.user.loginDialog,
            token: (state) => state.user.token,
            navActiveId: (state) => state.user.navActiveId,
            navList: (state) => state.app.navList,
        }),
        newRoutes() {
            const isShow = this.navList?.find((i) => i.id === 35975)?.IsShow || false;
            return this?.perRoutes?.filter((item) => {
                if (item.name == "release") {
                    return isShow;
                } else {
                    return true;
                }
            });
        },
        forumShow() {
            return;
        },
    },
    methods: {
        select({ item, key, selectedKeys }) {
            this.$router.push({ name: key });
        },
        initSidebar() {
            const matched = this.$route.matched[0].name;
            let arr = routes.options.routes
                .find((item) => item.name == matched)
                .children.filter((item) => !item.meta.isNoSide);
            const name = this.$route.name;
            // let openKeys = [];
            this.selectkeys = [this.$route.meta.select || this.$route.name];
            // arr.forEach((element) => {
            //     if (!element.children) return;
            //     element.children.forEach((item) => {
            //         if (item.name == name) {
            //             openKeys = [element.name];
            //         }
            //     });
            // });
            this.perRoutes = arr;
            this.openKeys = this.perRoutes.map(i => i.name);
            // console.log(openKeys, 'openKeys');
        },
        logOff(){
            this.$store.dispatch('user/logOff',)
        }
    },
    watch: {
        $route: {
            handler(newVal, oldVal) {
                if (newVal.meta.select) {
                    this.selectkeys = [newVal.meta.select];
                }
            },
        },
    },
    created() {
        this.initSidebar();
    },
};
</script>
<style scoped lang="less">
@import url("~@/assets/css/sidebar.less");
</style>
